<template>
  <el-row :gutter="20">
    <el-col :span="24" :offset="0">
      <el-table :data="list" style="width: 100%">

        <el-table-column label="Commande">
          <template #default="scope">
            {{ command(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column prop="createdBy" label="Créé par" width="220">
        </el-table-column>
        <el-table-column label="Date" width="180">
          <template #default="scope">
            {{ formatedDate(scope.row.createdAt) }}
          </template>
        </el-table-column>
        <el-table-column label="Statut" width="100">
          <template #default="scope">
            <el-tag size="small" :type="status(scope.row.status)?.type">{{ status(scope.row.status).label }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="En file" width="100">
          <template #default="scope">
            <el-tag size="small">{{ scope.row.queued ? 'Vrai' : 'Faux' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="Résultat">
          <template #default="scope">
            {{ result(scope.row) }}
          </template>
        </el-table-column>
        <el-table-column align="right">
          <template #header>
            <el-space alignment="center">
              <el-row style="padding-bottom: 44px; margin-right: -12px">
                <CreateCommand />
              </el-row>
            </el-space>
          </template>

          <template #default="scope">
            <el-tooltip :content="tooltipText(scope.row)" placement="top">
              <el-button v-if="scope.row.queued" size="small" :icon="DeleteIcon"
                @click="deletequeue(scope.row)"></el-button>
              <el-button v-if="canBeDeleted(scope.row)" size="small" :icon="DeleteIcon"
                @click="deleteCommand(scope.row)"></el-button>
            </el-tooltip>
          </template>

        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
  <el-row :gutter="20">
    <el-col :span="24" :offset="0">
      <el-pagination v-if="list.length > 0" v-model:currentPage="page" v-model:page-size="size" background
        layout="total, prev, pager, next, jumper" :total="totalElements" @current-change="setPage"
        :hide-on-single-page="true" />
    </el-col>
  </el-row>
</template>

<script>
import { mapState } from 'vuex'
import CreateCommand from '../components/CreateCommand.vue'
import _ from 'lodash'
import dayjs from 'dayjs'
export default {
  components: { CreateCommand },
  data() {
    return {
      canBeDeletedStatus: ['unknown', 'queued'],
      page: 1,
    }
  },
  computed: {
    ...mapState('devices', ['commands', 'device']),
    list() {
      return _.get(this.commands, 'content', [])
    },
    totalElements() {
      return _.get(this.commands, 'totalElements', 0)
    },
    size() {
      return _.get(this.commands, 'size', 0)
    },
  },
  mounted() { },
  methods: {
    tooltipText(row) {
      if (row.queued) {
        return "Delete Queue";
      } else if (this.canBeDeleted(row)) {
        return "Delete Command";
      } else {
        return "";
      }
    },

    status(val) {
      switch (val) {
        case 'queued':
          return { type: 'info', label: 'En attente' }
        case 'processed':
          return { type: 'success', label: 'Traité' }
        case 'ignored':
          return { type: 'danger', label: 'Ignoré' }

        default:
          return { type: 'warning', label: 'Inconnue' }
      }
    },
    setPage(page) {
      this.page = page
      this.$store.dispatch('devices/getCommandsApi', {
        deviceId: this.device?.id ?? null,
        params: { page: page - 1 },
      })
    },
    formatedDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    },
    command(row) {
      return _.get(row, 'attributes.data', '--')
    },
    result(row) {
      return _.get(row, 'attributes.result', '--')
    },
    canBeDeleted(row) {
      return this.canBeDeletedStatus.includes(row.status)
    },
    deleteCommand(row) {
      let payload = {
        deviceId: row.deviceId,
        commandId: row.id,
      }
      this.$store.dispatch('devices/deleteCommand', payload)
    },
    deletequeue(row) {
      let payload = {
        deviceId: row.deviceId,
        commandId: row.id,
      }
      this.$store.dispatch('devices/deleteQueued', payload)
    },
  },
}
</script>

<style></style>
