<template>
  <div>
    <div class="demo-image__error">
      <div class="row block ml-5">
        <div align="left">
          <el-space wrap>
            <h3> Détails</h3>
            <edit-device :row="device"></edit-device>
            <div>
              <el-button info size="default" @click="reloadPage(device)" circle>
                <el-icon>
                  <Refresh />
                </el-icon>
              </el-button>
            </div>
          </el-space>
        </div>
        <el-descriptions class="mt-0" title="" :column="3" direction="horizontal">
          <el-descriptions-item label="N° de série" span="1">
            {{ device.uniqueId }}
          </el-descriptions-item>

          <el-descriptions-item label="Nom" span="2">
            {{ device.name }}
          </el-descriptions-item>

          <el-descriptions-item label="Marque">
            {{ device.make }}</el-descriptions-item>
          <el-descriptions-item label="Modèle" span="2">
            {{ device.model }}
          </el-descriptions-item>
          <el-descriptions-item label="Organisation ">
            {{ organizationName }}
          </el-descriptions-item>
          <el-descriptions-item label="Revendeur" span="2">
            {{ device.retailer }}
          </el-descriptions-item>
          <el-descriptions-item label="Connexion" span="2">
            <el-tag class="mx-1" effect="dark" :type="setStatus(device.status)" round>
              {{ device.status }}
            </el-tag>
          </el-descriptions-item>
          <el-descriptions-item label="Etat">
            <el-tag class="mx-1" effect="light" type="info" round>
              {{ device.motionState }}
            </el-tag>
          </el-descriptions-item>
        </el-descriptions>
        <div align="left">
          <el-button class="mt-3" @click="show(device)">Afficher position</el-button>
        </div>
      </div>
      <div class="block ml-5">
        <div style="height: 350px; width: 100%">
          <l-map :options="{ zoomControl: true }" id="map" style="height: 80%; width: 100%" class="map" ref="map"
            :center="center" :zoom="zoom" @update:zoom="zoomUpdated" @update:center="centerUpdated">
            <l-tile-layer :url="url"> </l-tile-layer>
            <!-- <l-control-zoom position="bottomright"></l-control-zoom> -->
            <l-marker :lat-lng="markerLatLng" :v-model="markerLatLng">
              <l-popup>{{ text }}</l-popup>
            </l-marker>
          </l-map>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Refresh } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import L from 'leaflet'
import { LMap, LTileLayer, LMarker, LPopup } from 'vue3-leaflet'
import 'leaflet/dist/leaflet.css'
import { mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import EditDevice from '../components/EditDevice.vue'
import { ref } from 'vue'
import { Icon } from 'leaflet'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
})

export default {
  components: {
    EditDevice,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    Refresh,

  },
  props: [],
  data() {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 4,
      center: [48, 2],
      marker: null,
      motionState: ref('parked'),
      markerLatLng: [0, 0],
      text: '',
      map: null,
    }
  },

  mounted() { },
  computed: {
    ...mapFields('devices', ['filter']),
    ...mapState('geofence', ['item', 'item.latLngs', 'item.type']),
    ...mapState('devices', ['commands', 'device']),

    organizationName() {
      return this.device?.organization?.name
    },
    organizationTimezone() {
      return this.device?.organization?.timezone
    },
    organizationRegion() {
      return this.device?.organization?.region
    },
    organizationCurrency() {
      return this.device?.organization?.currency
    },
  },
  methods: {
    zoomUpdated(zoom) {
      this.zoom = zoom
    },

    centerUpdated(center) {
      this.center = center
    },
    handleEdit(index, row) {
      this.setDevice(row)
    },
    getOrganizations(query) {
      if (query) {
        this.loading = true
        let payload = {
          params: {
            q: query,
          },
        }
        this.$store
          .dispatch('organisations/getOrganisations', payload)
          .then((data) => {
            this.loading = false
            this.orgOptions = data
          })
      } else {
        this.orgOptions = []
      }
    },

    setDeviceMarker(latlng) {
      if (this.marker) {
        this.map.removeLayer(this.marker)
      }
      let deviceIcon = new L.icon({
        iconUrl: require('@/assets/marker-primary.svg'),
        iconSize: [20, 20],
        popupAnchor: [0, -10],
      })
      this.marker = L.marker(latlng, {
        draggable: false,
        icon: deviceIcon,
      }).addTo(this.map)
      this.map.setView(latlng, this.zoom)
    },

    show(device) {
      this.$store
        .dispatch('devices/getLastPosition', { deviceId: device.id })
        .then((data) => {
          this.text = data.address
          if (data.latlng) {
            this.markerLatLng = data.latlng
          } else {
            ElMessage({
              message: 'Aucune Position',
              type: 'error',
            })
          }
        })
        .catch((error) => {
          console.log(error.response)
        })
    },
    reloadPage(device) {
      this.$store.dispatch('devices/getDeviceApi', { deviceId: device.id })
    },
    setStatus(status) {
      switch (status) {
        case 'online':
          return 'success'
        case 'offline':
          return 'danger'
        default:
          return 'info'
      }
    },
  },
}
</script>
 

<style >
.el-descriptions {
  margin-top: 20px;
}

.cell-item {
  display: flex;
  align-items: center;
}

.margin-top {
  margin-top: 20px;
}

.demo-image__error .block {
  padding: 30px 0;
  text-align: center;
  border-right: solid 1px var(--el-border-color);
  display: inline-block;
  width: 43%;
  box-sizing: border-box;
  vertical-align: top;
}

.demo-image__error .demonstration {
  display: block;
  color: var(--el-text-color-secondary);
  font-size: 14px;
  margin-bottom: 20px;
}

.demo-image__error .el-image {
  padding: 0 5px;
  max-width: 300px;
  max-height: 200px;
  width: 100%;
  height: 200px;
}

.demo-image__error .image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}

.demo-image__error .image-slot .el-icon {
  font-size: 30px;
}

.block {
  margin-top: -12px;
  margin-right: -55px;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
</style>




