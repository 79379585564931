<template>
  <div>
    <el-tooltip class="item" effect="dark" content="Modifier" placement="bottom">
      <el-button :icon="EditIcon" size="small" @click="handleOpen"></el-button>
    </el-tooltip>
    <el-dialog title="Modification d'un boitier" v-model="dialogFormEdit" append-to-body center>
      <el-form ref="deviceForm" :model="form" :rules="rules">
        <el-form-item prop="uniqueId" label="N° de série : " :label-width="formLabelWidth">
          <el-input v-model.trim="form.uniqueId" autocomplete="off" placeholder="Numéro de série "></el-input>
        </el-form-item>
        <el-form-item label="N° d'appel :" :label-width="formLabelWidth">
          <el-input v-model.trim="form.phone" autocomplete="off" placeholder="Numéro d'appel "></el-input>
        </el-form-item>
        <el-form-item label="Marque :" prop="make" :label-width="formLabelWidth" placeholder="Marque">
          <el-select v-model="form.make" placeholder="Marque" style="width: 100%">
            <el-option label="Albatross" value="Albatross"></el-option>
            <el-option label="Teltonika" value="Teltonika"></el-option>
            <el-option label="Gt06" value="Gt06"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Modèle :" prop="model" :label-width="formLabelWidth" placeholder="Modèle">
          <el-select v-model="form.model" placeholder="Modèle " style="width: 100%">
            <el-option label="FMB130" value="FMB130"></el-option>
            <el-option label="FMB132" value="FMB132"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="isAppAdmin" label="Revendeur :" :label-width="formLabelWidth">
          <el-select v-model="form.retailerId" placeholder="Revendeur" style="width: 100%">
            <el-option v-for="(item, index) in retailers" :key="index" :label="item.name" :value="item.id"
              clearable></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Organisation :" :label-width="formLabelWidth">
          <el-select v-model="form.organizationId" placeholder="Organisation" filterable remote
            :remote-method="getOrganizations" :loading="loading" clearable>
            <el-option v-for="item in orgOptions" :key="item.id" :label="item.name" :value="item.id"
              clearable></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Attribute :" :label-width="formLabelWidth">
          <el-row :gutter="10" v-for="(attribute, index) in attributes" :key="index">
            <el-col :span="6">
              <el-select v-model="attribute.key" placeholder="Attribut" style="width: 100%">
                <el-option v-for="(item, index) in attributeOptions" :key="index" :label="item.label" :value="item.value"
                  clearable></el-option>
              </el-select>
            </el-col>
            <el-col :span="15">
              <el-input v-model="attribute.value" placeholder="Please input" />
            </el-col>
            <el-col :span="3">
              <el-button type="danger" plain :icon="DeleteIcon" @click="deleteAttribute(index)"></el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <el-row>
          <el-col :span="4"></el-col>
          <el-col :span="20">
            <el-button type="primary" plain :icon="PlusIcon" @click="addAttribute"></el-button>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormEdit = false" type="danger" plain>Annuler</el-button>
          <el-button type="primary" @click="handleEdit('deviceForm')">Confirmer</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import _ from 'lodash'
export default {
  props: {
    row: Object,
  },
  data() {
    return {
      dialogFormEdit: false,
      formLabelWidth: '140px',
      orgOptions: [],
      loading: false,
      attributes: [],
      attributeOptions: [
        { value: 'DOUT1' },
        { value: 'DOUT2' },
        { value: 'DOUT3' },
        { value: 'useOdometre' },
      ],
      form: {
        id: '',
        uniqueId: '',
        phone: '',
        make: '',
        model: '',
        organizationId: null,
        retailerId: null,
      },
      rules: {
        uniqueId: [
          {
            required: true,
            message: 'Please input serial number',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            message: 'Please input call number ',
            trigger: 'change',
          },
        ],
        make: [
          {
            required: true,
            message: 'Please input make',
            trigger: 'change',
          },
        ],
        model: [
          {
            required: false,
            message: 'Please input model',
            trigger: 'change',
          },
        ],
      },
    }
  },

  watch: {},
  computed: {
    ...mapState('auth', ['user']),
    ...mapState('devices', ['device']),
    ...mapState('Organisation', ['organisations']),
    ...mapState('retailers', ['retailers']),
    ...mapGetters('auth', ['isAppAdmin']),
    isAllowed() {
      let appRole = _.get(this.user, 'appRole', null)
      if (appRole == 'APP_USER' || appRole == 'APP_ADMIN') return true
      else return false
    },
  },
  methods: {
    handleOpen() {
      this.dialogFormEdit = true
      this.populate(this.row)
    },
    handleEdit(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let payload = {
            id: this.form.id,
            data: {
              uniqueId: this.form.uniqueId,
              make: this.form.make,
              model: this.form.model,
              organizationId: this.form.organizationId

            },
          }
          if (this.form.organizationId)
            payload.data.organizationId = this.form.organizationId

          if (this.form.retailerId)
            payload.data.retailerId = this.form.retailerId

          if (this.attributes.length)
            payload.data.attributes = this.mappedAttributes(this.attributes)

          this.$store
            .dispatch('devices/updateDeviceApi', payload)
            .then(() => (this.dialogFormEdit = false))
        } else {
          return false
        }
      })
    },
    mappedAttributes(attributes) {
      let result = {}
      attributes.forEach((obj) => {
        result[obj.key] = obj.value
      })
      return result
    },
    addAttribute() {
      this.attributes.push({
        key: 'DOUT1',
        value: '',
      })
    },
    deleteAttribute(index) {
      this.attributes.splice(index, 1)
    },
    populate(row) {
      this.attributes = []
      this.form.id = row.id
      this.form.uniqueId = row.uniqueId || null
      this.form.phone = row.phone || null
      this.form.make = row.make || null
      this.form.model = row.model || null
      if (_.get(row, 'organization', false)) {
        this.form.organizationId = row.organization.id
        this.orgOptions = [row.organization]
        this.loading = false
      }
      if (Object.keys(row.attributes).length != 0) {
        for (var propName in row.attributes) {
          this.attributes.push({
            key: propName,
            value: row.attributes[propName],
          })
        }
      }
    },
    getOrganizations(query) {
      if (query) {
        this.loading = true
        let payload = {
          params: {
            q: query,
          },
        }
        this.$store
          .dispatch('organisations/getOrganisations', payload)
          .then((data) => {
            this.loading = false
            this.orgOptions = data
          })
      } else {
        this.orgOptions = []
      }
    },
  },
}
</script>
