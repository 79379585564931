<template>
  <div>
    <el-dropdown @command="handleCommand">
      <span class="el-dropdown-link">
        Actions
        <el-icon>
          <ArrowDown />
        </el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="showDialog">Nouvelle commande</el-dropdown-item>
          <el-dropdown-item command="resendCommand">Ré-envoyer les commandes</el-dropdown-item>
          <el-dropdown-item command="reloadPage">Actualiser</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <!-- <div class="div">
      <el-tooltip
        class="item"
        effect="dark"
        content="Ajouter commande"
        placement="top"
      >
        <el-button
          type="primary"
          :icon="PlusIcon"
          plain
          @click="dialogVisible = true"
          >Ajouter</el-button
        >
      </el-tooltip>
    </div>
    <div class="div">
      <el-tooltip
        v-if="isQueued"
        class="item"
        effect="dark"
        content="Réenvoyer les commandes en attentes"
        placement="top"
      >
        <el-button
          type="primary"
          :icon="MessageIcon"
          plain
          @click="resendCommand"
          :disable="!resending"
        >
        </el-button>
      </el-tooltip>
      <el-button
        type="primary"
        :icon="Refresh"
        plain
        @click="reloadPage"
        :disable="!resending"
      >
        <el-icon><Refresh /></el-icon
      ></el-button>
    </div> -->

    <el-dialog title="Ajouter une commande" v-model="dialogVisible" append-to-body center>
      <div>
        <div class="mt-4">
          <el-input v-model.trim="value" placeholder="Entrez votre commande" class="input-with-select">

            <template #append v-if="device.make === 'Teltonika'">
              <el-select v-model="valuechange" placeholder="commandes prédéfinies" @change="changevalue()"
                :allow-create="false" style="width: 230px">

                <el-option v-for="item in sourceDataMap.type1" :key="item.value" :label="item.label"
                  :value="item.value" />
              </el-select>
            </template>
          </el-input>
        </div>

      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="dialogVisible = false" size="small">Annuler</el-button>
          <el-button type="primary" size="small" @click="handleCreate('commandForm')">Confirmer</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ref } from 'vue'
import { ArrowDown } from '@element-plus/icons-vue'
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
import _ from 'lodash'
import env from '../../../config/comands'
export default {
  components: {
    ArrowDown,
  },
  data() {
    return {
      sourceDataMap: env.SOURCE_DATA_MAP,
      value: ref(),
      valuechange: null,

      vehiclesOptions: [],
      dialogVisible: false,
      dialogFormSearch: false,
      formLabelWidth: '120px',
      form: {
        name: 'custom',
        type: 'custom',
        attributes: {
          data: null,
        },
      },
    }
  },
  computed: {
    ...mapState('devices', ['commands', 'device', 'resending']),
    isQueued() {
      let queued = _.filter(_.get(this.commands, 'content', []), {
        status: 'queued',
      })
      return queued.length > 0
    },
  },
  mounted() {

  },
  methods: {
    changevalue() {
      this.value = this.valuechange
    },
    handleCommand(command) {
      this[command]()
    },
    resendCommand() {
      this.$store.commit('device/setResending', true)
      let deviceId = this.device?.id ?? null
      if (!deviceId) {
        ElMessage.error('Device inconnu')
      }
      let payload = {
        deviceId: deviceId,
      }
      this.$store.dispatch('devices/resendCommand', payload)
    },
    showDialog() {
      this.dialogVisible = true
    },
    handleCreate() {
      this.form.attributes.data = this.value
      let payload = {
        deviceId: this.device.id,
        data: { ...this.form },
      }
      this.$store
        .dispatch('devices/addCommandApi', payload)
        .then(() => {
          this.dialogVisible = false
        })
        .then(() => {
          this.form.attributes.data = ' '
        })
    },

    reloadPage() {
      let payload = {
        deviceId: this.device?.id ?? null,
      }
      this.$store.dispatch('devices/getCommandsApi', payload)
    },
  },
}
</script>
<style>
.el-dialog--center .el-dialog__body {
  text-align: center;
  padding: 25px calc(var(--el-dialog-padding-primary) + 5px) 30px;
}

.el-dialog--center .el-dialog__footer {
  margin-right: 7px;
  text-align: right;

}
</style>