<template>
  <el-row>
    <el-col :span="24" :offset="0" style="margin-top: 30px;">
      <div class="block">
        <el-date-picker v-model="startDate" class="date" type="datetime" placeholder="Date debut"
          style="margin-right: 10px;" />
        <el-date-picker v-model="endDate" class="date" type="datetime" placeholder="Date fin"
          style="margin-right: 10px;" />
        <div class="champs">
        </div>
        <div class="attributes">
          <el-select v-model="selectedFields" multiple collapse-tags :reserve-keyword="false"
            placeholder="Choississez les champs" aria-required="true" style="width: 30%; margin-right: 10px;">
            <el-option v-for="item in fields" :key="item.value" :label="item.label" :value="item.value"
              :aria-required="true" />
          </el-select>
          <el-select v-model="selectedAttributes" multiple collapse-tags style="width: 30%;" default-first-option
            :reserve-keyword="false" placeholder="Choississez les attributs">
            <el-option v-for="item in attributes" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          <el-button @click="search" class="btn">
            Chercher </el-button>
        </div>
      </div>

    </el-col>

  </el-row>
  <div>
    <TraceTable :trace="trace" :selectedFields="selectedFields" :selectedAttributes="selectedAttributes" />
  </div>
</template>

<script>
import TraceTable from './TraceTable.vue'
import { mapState, mapActions } from 'vuex'
import _ from 'lodash'
import dayjs from 'dayjs'
import { ElMessage } from 'element-plus'
export default {
  components: { TraceTable },
  el: '#app',
  data() {
    return {
      errors: [],
      fields: [
        {
          value: 'fixTime',
          label: 'Date',
        },
        {
          value: 'speed',
          label: 'Vitesse',
        },
        {
          value: 'bearing',
          label: 'Direction',
        },
        {
          value: 'vehicleId',
          label: 'Véhicule',
        },
        {
          value: 'latitude',
          label: 'Latitude',
        },
        {
          value: 'longitude',
          label: 'Longitude',
        },
      ],
      attributes: [
        {
          value: 'ignition',
          label: 'Allumage',
        },
        {
          value: 'sat',
          label: 'Satellite',
        },
        {
          value: 'event',
          label: 'Événement',
        },
        {
          value: 'rssi',
          label: 'RSSI',
        },
        {
          value: 'motion',
          label: 'Mouvement',
        },
        {
          value: 'sleepMode',
          label: 'Mode veille',
        },
        {
          value: 'power',
          label: 'Alimentation',
        },
        {
          value: 'distance',
          label: 'Distance',
        },
        {
          value: 'gpsStatus',
          label: 'Statut GPS',
        },

        {
          value: 'coolantTemp',
          label: 'Température',
        },
        {
          value: 'pdop',
          label: 'PDOP',
        },
        {
          value: 'hdop',
          label: 'HDOP',
        },
        {
          value: 'speed',
          label: 'Vitesse',
        },
        {
          value: 'battery',
          label: 'Batterie',
        },
        {
          value: 'avgFuelUsed',
          label: 'Cons. carburant',
        },
        {
          value: 'odometer',
          label: 'Odometre',
        },
        {
          value: 'driverId',
          label: "conducteur",
        },
      ],

      canBeDeletedStatus: ['unknown', 'queued'],
      page: 1,
      startDate: null,
      endDate: null,
      selectedFields: [],
      selectedAttributes: [],
      trace: [],
    }
  },
  computed: {
    ...mapState('devices', ['commands', 'device']),
    list() {
      return _.get(this.commands, 'content', [])
    },
    totalElements() {
      return _.get(this.commands, 'totalElements', 0)
    },
    size() {
      return _.get(this.commands, 'size', 0)
    },
  },
  mounted() { },
  methods: {
    ...mapActions({
      getPositions: 'devices/getPositions',
    }),

    status(status) {
      switch (status) {
        case 'queued':
          return { type: 'primary', label: 'En attente' }
        case 'processed':
          return { type: 'success', label: 'Traité' }
        case 'ignored':
          return { type: 'danger', label: 'Ignoré' }

        default:
          return { type: 'warning', label: 'Inconnue' }
      }
    },
    setPage(page) {
      this.page = page
      this.$store.dispatch('devices/getCommandsApi', {
        deviceId: this.device?.id ?? null,
        params: { page: page - 1 },
      })
    },
    formatedDate(date) {
      return dayjs(date).format('YYYY-MM-DD HH:mm:ss')
    },
    command(row) {
      return _.get(row, 'attributes.data', '--')
    },
    result(row) {
      return _.get(row, 'attributes.result', '--')
    },
    canBeDeleted(row) {
      return this.canBeDeletedStatus.includes(row.status)
    },
    search() {
      if (!this.startDate || !this.endDate) {
        ElMessage.error({
          message: 'Veuillez sélectionner à la fois la date de début et la date de fin.',
        })

        return
      }
      const startTime = new Date(this.startDate).getTime()
      const endTime = new Date(this.endDate).getTime()
      if ((endTime - startTime) / (1000 * 60 * 60) > 24) {
        ElMessage.error({
          message: 'La durée entre les dates de début et de fin doit être supérieure à 24 heures !',
        })

        return
      }

      let payload = { deviceId: this.device.id }

      payload.startDate = this.startDate
      payload.endDate = this.endDate

      let tags = this.selectedFields.join(',').concat(',')
      tags = tags.slice(0, -1)
      tags += this.selectedAttributes
        .map((element) => 'attributes.' + element)
        .join(',')

      payload.tags = tags

      this.$store
        .dispatch('devices/getPositions', payload)
        .then((response) => (this.trace = response))
        .catch((error) => {
          console.log('error :', error)
        })
    }
  },
}
</script>
<style>
.block {
  width: 85%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}



.attributes {

  margin-right: 10px;

}

.btn {
  margin-left: 10px;
}

.date {

  flex: 1;
}
</style>