const env = {
  SOURCE_DATA_MAP: {
    type1: [
      {
        value: 'getinfo',
        label: 'getinfo',
      },
      {
        value: 'getstatus',
        label: 'getstatus',
      },
      {
        value: 'getgps',
        label: 'getgps',
      },
    ],
    type2: [
      {
        value: 'getinfo',
        label: 'getinfo',
      },
      {
        value: 'getstatus',
        label: 'getstatus',
      },
      {
        value: 'getgps',
        label: 'getgps',
      },
    ],
  },
}

export default env
